import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import LivePlayround from "~/components/LivePlayground/LivePlayground";
import Args from "~/components/Args/Args";
import AccessibilityTable from "~/components/AccessibilityTable/AccessibilityTable";
import ComponentStatusBar from "~/components/ComponentStatusBar/ComponentStatusBar";
import ComponentDescription from "~/components/ComponentDescription/ComponentDescription";
import ComponentImages from "~/components/ComponentImages/ComponentImages";
export const componentName = {
  display: "Cardstack",
  code: "Cardstack"
};
export const _frontmatter = {
  "menuLabel": "Cardstack",
  "sortOrder": 3.2
};
const layoutProps = {
  componentName,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Cardstack`}</h1>
    <ComponentDescription of={componentName.display} mdxType="ComponentDescription" />
    <ComponentStatusBar of={componentName.display} mdxType="ComponentStatusBar" />
    <hr></hr>
    <ComponentImages component={componentName} mdxType="ComponentImages" />
    <LivePlayround componentName={componentName} storybookLink="https://caesars-ui-storybook.pages.dev/?path=/story/components-cardstack--default-story" mdxType="LivePlayround" />
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { Cardstack } from "@caesars-digital/caesars-ui"
`}</code></pre>
    <p><inlineCode parentName="p">{`Cardstack`}</inlineCode>{` easily groups like elements together, meant for `}<inlineCode parentName="p">{`Cards`}</inlineCode>{`, but not limited to them.  The `}<inlineCode parentName="p">{`Cardstack`}</inlineCode>{` has all the same features
that the `}<inlineCode parentName="p">{`Card`}</inlineCode>{` component has when it comes to slots, such as the `}<inlineCode parentName="p">{`Header`}</inlineCode>{` or `}<inlineCode parentName="p">{`PreFooter`}</inlineCode>{`, its helpful to think of it as a `}<inlineCode parentName="p">{`Card`}</inlineCode>{` that
is meant to hold other `}<inlineCode parentName="p">{`Card`}</inlineCode>{`s.`}</p>
    <hr></hr>
    <h2>{`Properties`}</h2>
    <div className="text-size-4 mb-10"><span className="text-red-600">*</span> indicates required.</div>
    <Args component={componentName} className="mb-20" mdxType="Args" />
    <h2>{`Accessibility`}</h2>
    <AccessibilityTable className="mb-20" mdxType="AccessibilityTable">
    <AccessibilityTable.Principle principle="Percievable" status="ready">
        The Cardstack component has a clear visual indication that it is a Cardstack, such as a shape or label that distinguishes it from other page elements. It has sufficient contrast with the background to be easily visible, and any hover or focus states are also clearly distinguishable.
    </AccessibilityTable.Principle>
    <AccessibilityTable.Principle principle="Operable" status="ready">
        Users can interact with the Cardstack using a variety of input methods, including keyboard and mouse. The Cardstack also has a consistent behavior across the site, so that users can anticipate what will happen when they click on it.
    </AccessibilityTable.Principle>
    <AccessibilityTable.Principle principle="Understandable" status="ready">
        The Cardstack’s label or icon clearly communicates its purpose or the action that it will trigger. Users also receive appropriate feedback after clicking the Cardstack, such as a success message or a change in page content.
    </AccessibilityTable.Principle>
    <AccessibilityTable.Principle principle="Robust" status="ready">
        The Cardstack component is accessible to users with a range of abilities and devices, and adheres to web standards and best practices. The component is also tested thoroughly to ensure that it functions correctly in a variety of contexts and situations.
    </AccessibilityTable.Principle>
    </AccessibilityTable>
    

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      